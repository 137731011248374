<template>
  <div>
    <div class="d-flex flex-nowrap pb-2 mb-2" style="overflow-y: auto">
      <div
        v-for="key in tabs"
        :key="`t_${key}`"
        style="min-width: 200px; width: 33%"
        class="ma-1"
      >
        <v-btn
          block
          :color="key == tab ? 'primary' : 'defalut'"
          @click="changeTab(key)"
        >
          {{ $t(`labels.${key}`) }}
        </v-btn>
      </div>
    </div>

    <div>
      <Payable v-if="isActiveTab('payable_0')" />
      <ReceiptHistory v-else-if="isActiveTab('debt_history')" />
      <PaymentHistory v-else-if="isActiveTab('payment_history')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Debt",
  components: {
    Payable: () => import("@/components/debt/Payable"),
    ReceiptHistory: () => import("@/components/debt/ReceiptHistory"),
    PaymentHistory: () => import("@/components/debt/PaymentHistory"),
  },
  data: () => ({
    tabs: ["payable_0", "debt_history", "payment_history"],
    tab: "payable_0",
  }),
  computed: {},
  created() {},
  mounted() {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
};
</script>

<style scoped></style>
